<template>
  <div>
    <div class="card full-width">

      <div class="card-header border-0 pt-6 full-width">
        <!--begin::Card title-->

      </div>
      <div class="card-body pt-0">
        <Datatable
            class="table-button-actions-width"
            :total="total"
            :rowsPerPage="perPage"
            :loading="loading"
            :table-data="tableData"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            @current-change="onCurrentChange"
            @sort="sort"
            @items-per-page-change="changePerPage"
        >
          <template v-slot:cell-id="{ row: data }">{{ data.id }}</template>
          <template v-slot:cell-image="{ row: data }">
            <div class="demo-type">
              <el-image
                  class="image-in-list"
                  :src="data.image"
                  :preview-src-list="[data.image]"
                  :initial-index="1"
                  fit="cover"
              />
            </div>
          </template>
          <template v-slot:cell-name="{ row: data }">{{ data.name }}</template>
          <template v-slot:cell-email="{ row: data }">{{ data.email }}</template>
          <template v-slot:cell-role="{ row: data }">{{ data.role }}</template>
          <template v-slot:cell-is_super="{ row: data }">
            <span v-if="data.is_super" class="badge badge-primary">Super</span>
            <span v-else>-</span>
          </template>
          <template v-slot:cell-actions="{ row: data }">
            <el-row>
              <el-button type="default" size="small" @click="viewResource(data.id)" :icon="View" circle></el-button>
              <el-button type="primary" size="small" @click="editResource(data.id)" :icon="Edit" circle></el-button>
              <el-button type="danger" size="small" @click="deleteResource(data.id)" :icon="Delete" circle></el-button>
            </el-row>
          </template>

        </Datatable>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, reactive} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {Search, Edit, Delete, View} from '@element-plus/icons-vue'
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import CheckboxTableHeader from "@/components/admin/general/CheckboxTableHeader.vue";
import {confirmDelete} from "@/core/helpers/custom_notification";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {useRouter} from "vue-router/dist/vue-router";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
  name: "admins-list",
  components: {
    Datatable, CheckboxTableHeader
  },

  setup() {

    const tableHeader = ref([
      {name: "ID", key: "id", sortable: true,},
      {name: "Image", key: "image", sortable: false,},
      {name: "Name", key: "name", sortable: true,},
      {name: "Email", key: "email", sortable: true,},
      {name: "Role", key: "role", sortable: true,},
      {name: "Is Super", key: "is_super", sortable: true,},
      {name: "Actions", key: "actions",},

    ]);
    const router = useRouter();
    const tableData = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const currentPage = ref(1);
    const perPage = ref(0);
    const filters = ref({
      search: '',
      status: '',
      page: 1,
      per_page: 10
    });
    const filterOptions = ref({statuses: []});

    onMounted(() => {
      setCurrentPageBreadcrumbs("Admins", [], "create-admin");
    });


    const fetchData = async () => {
      loading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.get(APIs.ADMIN.list, filters.value);
        let data = response.data.data;
        tableData.value = data.admins;
        total.value = data.pagination_options.last_page;
        currentPage.value = data.pagination_options.currentPage;
        perPage.value = 1;
        loading.value = false;
      } catch (error) {
        handleError(error)
      }

    };

    const onCurrentChange = (value) => {
      tableData.value = [];
      filters.value.page = value;
    };
    const changePerPage = (value) => {
      filters.value.per_page = value;
    }
    const sort = (data) => {
    }
    const viewResource = (id) => {
      router.push({name: 'view-admin', params: {id: id}});
    }
    const editResource = (id) => {
      router.push({name: 'edit-admin', params: {id: id}});
    }
    const deleteResource = async (id) => {
      var checkConfirm = await confirmDelete();
      if (checkConfirm.isConfirmed) {
        Swal.fire({text: 'Pending ...'})
        ApiAxiosService.delete(APIs.ADMIN.delete(id)).then(function (response) {
          handleSuccess(response, 'swal');
          fetchData();
        }).catch(function (error) {
          handleError(error, 'swal');
        });

      }
    }
    watch(filters.value, (currentValue, oldValue) => {
      fetchData();
    });
    fetchData();
    // fetchFilerOptions();

    return {
      tableData,
      tableHeader,
      loading,
      fetchData,
      total,
      perPage,
      onCurrentChange,
      changePerPage,
      filters,
      sort,
      // fetchFilerOptions,
      filterOptions,
      Search, View, Edit, Delete,
      viewResource,
      editResource,
      deleteResource

    };
  },

});
</script>
